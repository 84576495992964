import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './index.scss';
import 'tailwindcss/tailwind.css';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { API, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING,  PRIVACY_POLICY} from '../../../constants';
import { PushDataLayerObject} from '../../../utility';

import Footer from '../FooterMobile';

const InfoButton = () => {
    const configuration = useSelector((state) => state.configuration);
    const { content } = configuration || {};
    const pageContentTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'page-content').translations;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');

    const privacy = pageContentTranslations?.find(x=> x.name === 'content-privacy');
    const pageContent = {
    'privacy': privacy
    };

    const onOpenModal = (origin) => {
       true && function() {
            //setModalContent(<Footer/>);
            setModalTitle("");
            setModalOpen(true);
        }();
          PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + Footer );
      };

    const onCloseModal = () => {
      setModalOpen(false);
    };

    const modalClassNames = {
        modal: 'rounded-2xl pt-10 px-8',
        closeIcon: 'optincta-modal__close-icon'
      }
    
    return (
        <>
        <Modal open={modalOpen} onClose={onCloseModal} classNames={modalClassNames} blockScroll={false}>
        <div>
            <h2 tabIndex="0" className={'optincta-modal__title font-semibold mb-6 spotlight'}>{modalTitle}</h2>
            <div >
            <Footer  />
              </div>
            
        </div>
    </Modal>
        <div className="fixed top-14 right-0 z-50 hidden  md:block ">
          <button onClick={() => onOpenModal('info')}
            className="inline-flex items-center px-4 py-2  border-transparent text-sm font-medium rounded-md  text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:px-6 md:py-3 md:text-base"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
              </svg>

                
                
            </button>
        </div>
        </>
      );
};

export default InfoButton;