import React, { useEffect, useState } from 'react';
import './index.scss'
import Heading from "../../atoms/heading/Heading";
import Text from "../../atoms/text/Text";
import { PATH_PAGE_SURVEY_ONBOARDING} from '../../../constants';
import Infopod from '../infopod/Infopod';

const PageDescriptionComponent = ({ data, page }) => {
  const [mobile, setMobile] = useState((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 710);

  useEffect(() => {
    const handleWindowResize = () => setMobile((
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth) < 710)
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [setMobile]);

  const { object } = data || {};
  const { heading, description } = object || {};
  const infopod = object['info-pod']

  let componentClasses = "2xl:w-1/2 lg:w-1/2 md:w-full sm:w-full  text-left md:pt-12 pb-24 4xl:pb-0 sm:pb-0 order-1 md:order-last sm:mt-1 sm:px-6 sm:pt-6 sm:pb-10 md:pb-10 md:px-4 hero__content 4xl:flex 4xl:items-center";
  let parentClasses = "page-description-container";
  let wrapperClasses = "page-description-wrapper";
  // line-15-19 needs to be removed once a sass file has been built for this page and subsequent pages
  if (page === PATH_PAGE_SURVEY_ONBOARDING) {
    componentClasses = "mx-auto h-full flex sm:flex-wrap 2xl:align-middle 2xl:items-center px-20 sm:pb-0 md:pb-8 lg:pb-8  sm:px-10 hero-wrapper page-description mb-4 sm:mb-0";
    parentClasses = "page-description-container";
    wrapperClasses = "w-1/2 lg:w-1/2 md:w-3/4 sm:w-full py-10";
  }

  return (data &&
    <div className={componentClasses}>
      <div className={parentClasses}>
        {
          page === PATH_PAGE_SURVEY_ONBOARDING ?
            <>
              {
                mobile ? <VerticalComponent wrapperClasses={wrapperClasses} heading={heading} description={description} infopod={infopod} />
                  : <HorizontalComponent wrapperClasses={wrapperClasses} heading={heading} description={description} infopod={infopod} />
              }
            </> : <VerticalComponent wrapperClasses={wrapperClasses} heading={heading} description={description} infopod={infopod} />
        }
      </div>
    </div>
  )
}

export default PageDescriptionComponent;

const HorizontalComponent = ({ wrapperClasses, heading, description, infopod }) => {
  return (
    <div className='dflex'>
      <div className={wrapperClasses}>
        <Heading> {heading} </Heading>
        <Text> {description} </Text>
      </div>
      <div className="infopodContainer">
        <Infopod infopod={infopod} />
      </div>
    </div>
  )
}

const VerticalComponent = ({ wrapperClasses, heading, description, infopod }) => {
  return (
    <div className={wrapperClasses}>
      <Heading> {heading} </Heading>
      <Text className=" md:mb-4"> {description}  </Text>
      <Infopod infopod={infopod} />
    </div>
  )
}