import React, { Component, Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import Hero from '../../molecules/Hero';
import PageDescription from '../../molecules//PageDescription';
import InfoListSteps from '../../molecules/infolist-steps';
import InfoListBullets from '../../molecules/infolist-bullets';
import PageIssue from '../../molecules/PageIssue';
import Anchor from '../../atoms/anchor';
import OptInCta from '../../molecules/OptInCta';
import SurveyOverviewSection from '../../molecules/SurveyOverviewSection';
import InfolistParagraphAndMedia from '../../molecules/infolist-paragraphs-and-media';
import DownloadReportCTA from '../../molecules/DownloadReportCTA';
import { PATH_PAGE_LANDING, PATH_PAGE_SURVEY_ONBOARDING, PATH_PAGE_SURVEY_RESULT, SURVEY, PATH_PAGE_LOCALE, PATH_PAGE_REDIRECT1, PATH_PAGE_REDIRECT2, PATH_REDIRECT_USER, GILD_PAGEVIEW, GILD_EVENT, BUTTONS, CLICKED } from '../../../constants';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import {PushDataLayerObject} from '../../../utility';
import qs from 'query-string';
import Infofooter from "../../molecules/infofooter/Infofooter";

const TitleComponent = ({ title }) => {
    return (
        <Helmet>
            <title>{title}</title>
        </Helmet>
    );
};

const allComponents = {
    'c-hero': Hero,
    'c-page-description': PageDescription,
    'c-infolist-steps': InfoListSteps,
    'c-infolist-tick-bullets': InfoListBullets,
    'c-cta': Anchor,
    'c-survey-snapshot': SurveyOverviewSection,
    'c-opt-in': OptInCta,
    'c-infolist-paragraphs-and-media': InfolistParagraphAndMedia,
    'c-survey-results-pdf': DownloadReportCTA,
    'c-page-issue': PageIssue,
    'c-communications': Infofooter
}


const PageGeneratorComponent = (props) => {

    function _ScrollToTop(props) {
        const { pathname } = useLocation();
        useEffect(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }, [pathname]);
        return props.children
      }

    const dispatch = useDispatch();
    const [redirectPage, setRedirectPage] = useState(false);
    const configuration = useSelector((state) => state.configuration);

    const { survey } = configuration || {};
    const surveySections = survey?.content?.components?.find(x=> x.id === SURVEY).object.sections;


    let pageComponents = [];
    const { location } = props || {};
    const { pathname } = location;

    const history = useHistory();
    const url = window.location.href;
    let splitedUrl = url.split('?');
    let locale = splitedUrl[1]?.split('=')[1];
    let localeUrl = `${PATH_PAGE_LOCALE}${locale}`;

    let pageName = "";
    let source = [];
    let title = '';

    useEffect(() => {
        let interval = null;
        if(pathname === PATH_PAGE_REDIRECT1 || pathname === PATH_PAGE_REDIRECT2) {
            setRedirectPage(true);
            const pageName = pathname.replace('/','');
            const queryParam = qs.parse(location.search);

            PushDataLayerObject(configuration,
                GILD_EVENT,
                BUTTONS,
                CLICKED,
                pageName
              );
           interval = setInterval(() => {
              history.push(`${PATH_PAGE_LOCALE}${queryParam.locale}`);

              clearInterval(interval);
           }, 1000);
        }
      }, [redirectPage, configuration]);

    if(pathname === PATH_PAGE_REDIRECT1 || pathname === PATH_PAGE_REDIRECT2) {
        pageName = pathname.replace('/','');
        const { landingPage } = configuration || {};
        source = landingPage;
        title = pageName;

        return (
            <_ScrollToTop>
                <Fragment>
                    <TitleComponent title={title} />
                    {
                        <div style={{minHeight: '68vh', backgroundColor:'#e7ebf1'}}>
                            <img style={{height:'100px', position: 'absolute', top: '25%', left: '50%', marginLeft:'-50px', margingTop: '-50px'}} src="https://i.imgur.com/GLdqYB2.gif" />
                        </div>
                    }
                </Fragment>
            </_ScrollToTop>
        )
    }

    if(pathname === PATH_PAGE_LANDING) {
        pageName = "landingPage";
        const { landingPage } = configuration || {};
        source = landingPage;
        title = configuration?.landingPage?.content?.pageInfo?.pageTitle || '';
    }

    if(pathname === PATH_PAGE_SURVEY_ONBOARDING) {
        // survey
        pageName = "survey-onbaording";
        const { surveyOnboardingPage } = configuration || {};
        source = surveyOnboardingPage || {};
        title = surveyOnboardingPage?.content?.pageInfo?.pageTitle  || '';
    }

    if(pathname === PATH_PAGE_SURVEY_RESULT) {
        // survey/results
        pageName = "results";
        const { surveyResultsPage } = configuration || {};
        source = surveyResultsPage || {};
        title = surveyResultsPage?.content?.pageInfo?.pageTitle || '';
    }

    if(props.isPageNotFound) {
        // PAGE NOT FOUND
        pageName = "page not found";
        const { pageNotFound } = configuration || {};
        source = pageNotFound || {};
    }

    if(props.isPageError) {
        // PAGE ERROR
        pageName = "page error";
        const { pageError } = configuration || {};
        source = pageError || {};
    }

    const { content } = source || {};
    const { components } = content || {};


    (components || []).forEach((component, index) => {
        const { id, object } = component || {};
        if(allComponents.hasOwnProperty(id)) {
            const newComponent = {

                data: component,
                component: allComponents[id]
            }
            pageComponents.push(newComponent);
        }
    });

    const dispatchReduxAction = (action) => {
        dispatch(action)
    }

    return (
        <_ScrollToTop>
            <Fragment>
                <TitleComponent title={title} />
                {
                    (pageComponents || []).map((c, index ) => {
                        const { id, data, component:Component } = c || {};

                        return (
                            <Component data = { data } id = { id } key = { index } page={ pathname } surveySections={surveySections} dispatchReduxAction={dispatchReduxAction}/>
                        )
                    })
                }
            </Fragment>
        </_ScrollToTop>
    )
}


export default PageGeneratorComponent;
