import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfoListStepsComponent from './InfoListSteps';
import { INFO_LIST_STEPS_COMPONENT } from '../../../constants';

const InfoListSteps = ({ data }) => {

  return( 
    <InfoListStepsComponent data={data} />
  )

}

export default InfoListSteps;