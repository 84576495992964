import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AnchorComponent from "./Anchor";
import { ANCHOR_COMPONENT } from "../../../constants";

const Anchor = ({ data, id }) => {

  return <AnchorComponent data={data} id = { id }/>;
};

export default Anchor;
