export const GET_CONFIGURATION = 'GET_CONFIGURATION';
export const RECEIVE_CONFIGURATION = 'RECEIVE_CONFIGURATION';
export const ON_ERROR = 'ON_ERROR';
export const UPDATE_CURRENT_SECTION_NUMBER = 'UPDATE_CURRENT_SECTION_NUMBER';
export const UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE = 'UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE';
export const SURVEY_PAGES = 'SURVEY_PAGES';
export const VISITED_PAGES = 'VISITED_PAGES';
export const COMPLETED_PAGES = 'COMPLETED_PAGES';
export const SERVER_ERROR = 'SERVER_ERROR';

export function getConfiguration(payload) {
  return {
    type: GET_CONFIGURATION,
    payload,
  };
}

export function updateSectionPageNumber(payload) {
  return {
    type: UPDATE_CURRENT_SECTION_NUMBER,
    payload,
  };
}

export function updateTermsAndConditionAcceptance(payload) {
  return {
    type: UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE,
    payload,
  };
}


export function receiveConfiguration(payload) {
  return {
    type: RECEIVE_CONFIGURATION,
    payload,
  };
}

export function getSurveyPages(payload) {
  return {
    type: SURVEY_PAGES,
    payload,
  };
}

export function setVisitedPages(payload) {
  return {
    type: VISITED_PAGES,
    payload,
  };
}

export function setCompletedPages(payload) {
  return {
    type: COMPLETED_PAGES,
    payload,
  };
}

export function onError() {
  return {
    type: ON_ERROR,
  };
}

export function onServerError() {
  return {
    type: SERVER_ERROR,
  };
 }