import React, { useEffect } from 'react';
import ResultsPDFComponent from './DownloadReportCTA';


const DownloadReportCTA = ({ data, id, page }) => {
  return(data && 
    <ResultsPDFComponent data={data} id={id} page={page} />
  )
}

export default DownloadReportCTA;