import SectionComponent1 from "../components/organisms/Section";
import { PushPageViewDataLayerObject, GetPath } from '../utility';
import { GILD_PAGEVIEW, SURVEY_PAGE_NAME } from '../constants';
import { useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import './section.scss';

const TitleComponent = ({ title }) => {
  return (
    <Helmet>
      <title>{title ? title : ""}</title>
    </Helmet>
  );
};

const Section = () => {
  const configuration = useSelector((state) => state.configuration);
  const { survey } = configuration || {};
  const title = survey?.content?.pageInfo?.pageTitle || "";

  const completeUrl = window.location.href;
  PushPageViewDataLayerObject(GILD_PAGEVIEW, SURVEY_PAGE_NAME, GetPath(completeUrl));

  return (
    <>
      <TitleComponent title={title} />
      <div className="survey-wrapper">
        <div  role="form" id="Encuesta" aria-label="Encuesta" className="survey-container">
          {/* <ProgressPoints/> */}
          <div className="survey pt-5 ">

            <div className="image-wrapper text-center my-8 ">
              {/* <img src={ section1 } alt="section-1" className="mx-auto"></img> */}
            </div>

            <div className="fields-wrapper">
              <SectionComponent1 />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Section