import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PDFDownloadLink, Document, Page, Text, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLocation, Switch } from 'react-router-dom';
import generatePDFDocument from './generatePdfDocument';
import { PushDataLayerObject } from '../../../utility';
import { SURVEY, Q_ADDITIONAL_COMMENTS, PATH_REDIRECT_USER,PATH_PAGE_FEEDBACK, GILD_EVENT ,BUTTONS,CLICKED, DOWNLOAD_REPORT, PATH_PAGE_LOCALE } from '../../../constants';
import GeneratePdfDocument from "./generatePdfDocument";

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scroll({
          top: 0, 
          left: 0, 
          behavior: 'smooth'
        });
    }, [pathname]);
    return props.children
  }

const DownloadReportCTA = ({ data, page }) => {

    const { object } = data || {}; //JSON config of the component
    const acceptedTCs = useSelector((state) => state.acceptedTCs);
    const configuration = useSelector((state) => state.configuration);
    const history = useHistory();
    const [resultsState, setResultsState] = useState([]);
    const [additionalCommentsState, setAdditionalCommentsState] = useState('');
    const dispatch = useDispatch();
  
    const redirectIfNonAcceptedTCs = () => {
        if(acceptedTCs === false){
            history.push(PATH_REDIRECT_USER);
        }
    }
    
    const { survey } = configuration;
    const { surveyResultsPage } = configuration || {};

    const { content } = surveyResultsPage || {};
    const { pdfMeta } = content || {};

    useEffect(() => {
        redirectIfNonAcceptedTCs();

        let isMounted = true; // note this flag denote mount status
            if (isMounted) {
                if(survey) {
                const cSurvey = survey.content.components.find(x=> x.id === SURVEY);
                const cAdditionalComments = survey.content.components.find(x=> x.id === Q_ADDITIONAL_COMMENTS);
                // const results = cSurvey.object.results
                const results = cSurvey.object.sections;
                setResultsState(results);
                setAdditionalCommentsState(cAdditionalComments);
            }
        }
        isMounted = false;
    }, [configuration]);

    // Render this component only if the survey data is available
    if(!survey) return null;

    var styled = StyleSheet.create({
        hidden: {
            display:'none'
        },
        result: {
            textAlign: 'left',
            color: 'white',
            border: '2px solid white',
            padding: '10px'
        },
        section: {
            color: 'red'
        },
        question: {
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        data: {
            color: '#8ef6fc'
        },
        message: {
            color:'#fafc8e'
        }
      });

    const url = window.location.href;
    let splitedUrl = url.split('?');
    let locale = splitedUrl[1]?.split('=')[1];
    let localeUrl = `${PATH_PAGE_LOCALE}${locale}`;

    const logGA = () => {
        PushDataLayerObject(configuration, GILD_EVENT, BUTTONS, CLICKED, DOWNLOAD_REPORT)
    }

    return (
        <_ScrollToTop>
            <Fragment>
                <div className='cta-div text-center md:fixed md:bottom-0 md:left-0 md:right-0 md:flex md:justify-center mb-4 md:mb-0 md:z-50 md:p-4'>

                    <GeneratePdfDocument pdfMeta={pdfMeta} resultsState={resultsState} additionalCommentsState={additionalCommentsState} >
                        {object.text}
                    </GeneratePdfDocument>

                    <div style={styled.hidden}>

                    //Below code is for future - Tier based messages.
                    {(resultsState || []).map((section, sectionIndex) => {
                        return (
                            <div key={sectionIndex}>
                            <div style={styled.result}><strong>{section.name}</strong></div>
                            <div key={sectionIndex}>
                                {
                                section.questions.map((quest, questIndex) => {
                                    const answer = quest.answer.find(x =>x.answered === 'true');
                                    const message = answer && (answer.score === 'T1' || answer.score === 'T2' || answer.score === 'T3')? "-" + answer.scoreMessage: "";
                                    
                                    if(!answer) return;

                                    return (
                                    <div key={questIndex} style={styled.result}>
                                        <p>Question: {quest.question}</p>
                                        <p>Answer: {answer.text}</p>
                                        <p>MESSAGE: {message}</p>
                                    </div>
                                    )
                                })
                                } 
                            </div>     
                            
                            </div>  
                        ) 
                        })}
                    </div>
            </div>
        </Fragment>
        </_ScrollToTop>
    )    
}

export default DownloadReportCTA;