import React from 'react';
import { API } from '../../../constants';
import './index.scss';

const Infopod = ({ infopod }) => {
    const img = infopod['icon-image'];
    return (
        infopod.title && (img ? <WithIconImage infopod={infopod} /> : <WithoutIconImage infopod={infopod} />)
    )
}

export default Infopod;

const WithIconImage = ({ infopod }) => {
    const { title, text } = infopod || {};
    const imgSrc = infopod['icon-image'];

    return (
        <div className='infopodwrapper'>
            <div className='infopod-image-container flex mb-10px'>
                <div className='infopod-image width-40 mt-15px'>
                    <div>
                        <img src={`${API + '/images/' + imgSrc}`} className="logo mx-auto" />
                    </div>
                </div>
                <div className='width-60 mt-25px'>
                    <p>{title}</p>
                    <p className='font-25px'><strong>{text} </strong></p>
                </div>
            </div>
        </div>
    )
}

const WithoutIconImage = ({ infopod }) => {
    const { title, text } = infopod || {};

    return (
        <div className='infopodwrapper'>
           <b> {title}</b><br /><br />
            {text}
        </div>
    )
}