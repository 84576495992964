import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = ({ className, src, alt, height, width }) => (
    <LazyLoadImage
        role="img" 
        aria-label={alt} 
        alt={alt}
        height={height && height}
        src={src}
        width={width && width}
        wrapperClassName={className && className}
    />
);

export default Image;
