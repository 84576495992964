import React from 'react';
import {API} from '../../../constants';
import './Hero.scss';
import PageDescription from "../PageDescription";
import LazyLoadImage from '../../atoms/image/Image';

const Hero = ({data}) => {   
  const { id, object } = data || {};
  const { image, pageDescription } = object[0] || {};

  const { Desktop, Mobile, altText } = image || {};

  return (data &&
    <div id = { id } className='hero' role="banner">
      <div className='container mx-auto h-full flex md:flex-wrap 2xl:align-middle 2xl:items-center px-20 sm:pb-0 sm:px-5 hero-wrapper'>
          <PageDescription data = { pageDescription }/>

          <div role="figure" aria-labelledby={altText} className="flex justify-center 4xl:w-full sm:w-full lg:w-1/2 md:w-full items-end ml-auto sm:mx-auto order-2 md:order-1 sm:h-auto sm:pb-0 hero">
            <picture>
              <source media="(min-width:768px)" srcSet={`${API}/images/${Desktop}`}/>
              <source media="(min-width:767px)" srcSet={`${API}/images/${Mobile}`}/>
              <LazyLoadImage src={`${API}/images/${Desktop}`} alt={altText}/>
            </picture>
          </div>
          
      </div>
    </div>  
  )
}

export default Hero;