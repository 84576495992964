import React from 'react';
import PageIssueComponent from './PageIssue';

const PageIssue = ({ data, isStatic}) => {
  return(data && 
    <PageIssueComponent data={data} isStatic={isStatic}/>
  )
}

export default PageIssue;
