import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { IoAlertCircle } from "react-icons/io5";
import Text from "../../atoms/text/Text";
import Anchor from  "../../atoms/anchor/Anchor"
import Checkbox from  "../../atoms/checkbox/Checkbox"
import {Link, useHistory} from 'react-router-dom';
import { updateTermsAndConditionAcceptance } from '../../../redux/actions/configuration'
import './index.scss'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {PushDataLayerObject} from '../../../utility';
import {
  GILD_EVENT,
  BOX_T_AND_C_AND_PP, BUTTONS, ACCEPTED, ACCEPTING_TERM_AND_CONDITIONS_AND_PRIVACY_POLICY, START_THE_SURVEY,
  INTERNAL_LINKS, CLICKED, SEEING, TERM_AND_CONDITION, PRIVACY_POLICY
} from '../../../constants';

const OptInCta = ({data, dispatchReduxAction}) => {

    const history = useHistory();
    const [checked, setChecked] = useState(false);
    const [showCheckboxWarning, setShowCheckboxWarning] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const configuration = useSelector((state) => state.configuration);
    const { content } = configuration || {};
    const pageContentTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'page-content').translations;
    const terms = pageContentTranslations?.find(x=> x.name === 'content-terms')
    const privacy = pageContentTranslations?.find(x=> x.name === 'content-privacy')

    const pageContent = {
      'terms': terms,
      'privacy': privacy
    };
    const onOpenModal = (origin) => {
      pageContent[origin] && function() {
          setModalContent(pageContent[origin]?.text);
          setModalTitle(pageContent[origin]?.heading);
          setModalOpen(true);
      }();

      if (origin === 'terms') {
        PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + TERM_AND_CONDITION);
      }
      if (origin === 'privacy') {
        PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + PRIVACY_POLICY);
      }
    };
    const onCloseModal = () => {
      setModalOpen(false);
    };

    const handleCheckboxClick = (event) => {
      PushDataLayerObject(
        configuration,
        GILD_EVENT, 
        BOX_T_AND_C_AND_PP,
        ACCEPTED,
        ACCEPTING_TERM_AND_CONDITIONS_AND_PRIVACY_POLICY
        );  

        setChecked(prevValue => !prevValue); // invert value
    }

    useEffect(() => {
      dispatchReduxAction(updateTermsAndConditionAcceptance(true));
    }, [checked]);

    const handleSurveyButtonClick = (event) => {
      PushDataLayerObject(
          configuration,
          GILD_EVENT, 
          BUTTONS,
          CLICKED,
          START_THE_SURVEY
          );  
        
        event.preventDefault();
        setShowCheckboxWarning(false);

        checked ? history.push(data.object.cta.link) : setShowCheckboxWarning(true);
    }

    const modalClassNames = {
      modal: 'rounded-2xl pt-10 px-8',
      closeIcon: 'optincta-modal__close-icon'
    }

  return (data &&
    <>
    <div className="md:fixed md:bottom-0 md:left-0 md:right-0 md:z-50"> 
      <Modal open={modalOpen} onClose={onCloseModal} classNames={modalClassNames}>
      <h2 tabIndex="0" className={'optincta-modal__title font-semibold mb-6'}>{modalTitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>
    { showCheckboxWarning &&
      <div className="flex justify-center items-center font-medium py-4 sm:px-8 lg:px-24 bg-gray-75 rounded-md text-primary-350">
            <div slot="avatar" className="xs:-mt-12 sm:-mt-8 mr-2">    
            <IoAlertCircle className={'sm:h-8 sm:w-8 h-10 w-10'}/>
            </div>
            <div className="text-xl font-normal max-w-full flex-initial pr-2">
                <div className="py-2">
                   <div className="text-sm font-base font-bold">{data.object.errorMessage}</div>
                </div>
            </div>
        </div>
    }
    <div className='cta-div text-center'>
      <div className="inline-flex xs:px-6 2xs:px-8 3xs:pr-10 3xs:pl-12 mt-8 sm:max-w-full 4xl:max-w-24">
          <label className="flex items-center text-gray-50">
              <Checkbox checkboxChecked={checked} handleCheckboxClick={handleCheckboxClick} errorMessage={data.object.errorMessage}/>
          </label>
          <Text>  
              <span className="block text-left text-white text-14">
              {data.object.checkbox['text-before-terms']} <a tabIndex='3' onKeyDown={(e)=>{ if (e.key === 'Enter') {onOpenModal('terms')}}} onClick={()=>{ onOpenModal('terms')}} className="link underline">{data.object.checkbox['cta-terms-text']} </a> 
              &nbsp;
              {data.object.checkbox['and']} <a tabIndex='4' onKeyDown={(e)=>{ if (e.key === 'Enter') {onOpenModal('terms')}}} onClick={()=>{ onOpenModal('privacy')}} className="link underline">{data.object.checkbox['cta-privacy-text']} </a>
              </span> 
          </Text>
      </div>

        <Anchor tabIndex="5" data={data} onClick={handleSurveyButtonClick} hasFixed={true}/>
    </div>
    </div>
    </>
  )
}

export default OptInCta;