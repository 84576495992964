import React, { useEffect } from 'react';
import HeroComponent from './Hero';


const Hero = ({ data, id }) => {
  return(data && 
    <HeroComponent data={data} />
  )
}

export default Hero;