import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PageDescriptionComponent from './PageDescriptionComponent';
import { PAGE_DESCRIPTION_COMPONENT } from '../../../constants';

const PageDescription = ({ data, page }) => {

  return(data && 
    <PageDescriptionComponent data={data} page={ page }/>
  )

}

export default PageDescription;