import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './index.scss';
import LazyLoadImage from '../../atoms/image/Image';
import { OPTINCTA_COMPONENT, GILD_EVENT, BUTTONS, CLICKED } from '../../../constants';
import {PushDataLayerObject} from '../../../utility';

  const Anchor = ({data, onClick, tabIndex, hasFixed=false}) => {

    const conditionalClasses = !hasFixed ? 'md:fixed md:bottom-0 md:left-0 md:right-0 md:flex md:justify-center mb-4 md:mb-0 md:z-50 md:p-4': '';

    const configuration = useSelector((state) => state.configuration);

    const object = data.id === OPTINCTA_COMPONENT ? data.object.cta : data.object;
    const eventDetails = data.id === OPTINCTA_COMPONENT ? null : data.object.event;

    const onClickAnchor = (e) => {
      if (configuration && eventDetails) {
        PushDataLayerObject(
          configuration,
          GILD_EVENT, 
          BUTTONS,
          CLICKED,
          eventDetails.label
          )
      }
      if(onClick)onClick(e);
    }

  return (data &&
    
    <div className={`cta-div text-center ${conditionalClasses} sm:py-0 sm:px-4 sm:min-h-0 `}>
      <Link role="button" tabIndex={tabIndex} onClick={onClickAnchor} className='place-content-center sm:my-4 sm:w-full sm:px-0 md:mb-8 mt-8 px-6 text-center bg-primary-900 hover:bg-opacity-75 transition-colors duration-200 rounded-full font-semibold py-4 xs:min-w-17 2xs:min-w-20 3xs:min-w-22 4xl:min-w-26 inline-flex cta' to={ object.link }>
           {object.text}
           {(object.iconImage && object.iconImage !== '') &&
               <LazyLoadImage className='w-5 ml-2' src={object.iconImage} alt={object.text} />
           }
       </Link>
    </div>
  )
}

export default Anchor;