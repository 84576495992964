import React from 'react';
import './index.scss'
import Heading from "../../atoms/heading/Heading";
import { API } from '../../../constants';
import LazyLoadImage from '../../atoms/image/Image';

const SectionComponent = ({ data, questionNumberLabels }) => {
  const { Desktop, Mobile, altText } = data.image || {};

  return( data &&
    <div className="text-center ">
      <div className="section__title text-sm font-medium pb-2">{questionNumberLabels[0].text} {data.pageNumber} </div>
      <Heading> {data.name} </Heading>
      {
        Desktop && Mobile && (<div role="figure" aria-labelledby={altText} className="mx-auto max-w-3xl md:max-w-3xl lg:max-w-3xl 2xl:max-w-3xl 3xl:max-w-3xl md:mx-auto lg:mx-auto 2xl:mx-auto 3xl:mx-auto px-8 sm:px-10 mb-6">
           <picture>
              <source media="(min-width:768px)" srcSet={`${API}/images/${Desktop}`}/>
              <source media="(min-width:767px)" srcSet={`${API}/images/${Mobile}`}/>
              <LazyLoadImage className='section-image text-center mx-auto' src={ API + '/images/' + Desktop } alt={altText}/>
            </picture>
          </div>)
      }
    </div>
  )
}

export default SectionComponent;