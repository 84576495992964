import { Fragment, useState } from "react";
import { API, Q_MCQ, GILD_EVENT, EVENT_LABEL_MESSAGE, Q_FEEDBACK_RATING, Q_FEEDBACK_YES_NO } from '../../../../constants';
import Image from "../../../atoms/image/Image";

const Feedback = ({ currentQuestion, questionIndex, onValueChange, setFeedbackAnswers }) => {
  const { id, question, answer, properties } = currentQuestion;
  const { startLabel, endLabel } = properties[0] || [];
  const [allAnswers, setAllAnswers] = useState(answer);

  const handleOnChange = (index, answerIndex) => {
    let updatedAnswers = [];
    for (let i = 0; i < answer.length; i++) {
      if (answerIndex === i) {
        updatedAnswers.push(allAnswers[answerIndex]);
        updatedAnswers[i].displayImage = answer[i].iconImageSelected
      } else if (answerIndex != i) {
        updatedAnswers.push(allAnswers[i]);
        updatedAnswers[i].displayImage = answer[i].iconImage;
      }
      setAllAnswers(updatedAnswers);
    }
    onValueChange(index, answerIndex);
  };

  const setFeedback = (index, answerIndex) => {
    const event = GILD_EVENT;
    const eventLabel = EVENT_LABEL_MESSAGE + " " + question + " " + (parseInt(answerIndex) + 1);
    setFeedbackAnswers(index, event, eventLabel);
  }

  const Onhover = (answerIndex) => {
    let updatedAnswers = [];
    for (let i = 0; i < answer.length; i++) {
      if (answerIndex === i) {
        updatedAnswers.push(allAnswers[answerIndex]);
        updatedAnswers[i].displayImage = answer[i].iconImageRollover
      }
      if (answerIndex != i){
        updatedAnswers.push(allAnswers[i]);
        updatedAnswers[i].displayImage = answer[i].iconImage;
      }

      if (allAnswers[i].answered === "true") {
        updatedAnswers[i].displayImage = answer[i].iconImageSelected;
      }

      setAllAnswers(updatedAnswers);
    }
  };

  return (
    <Fragment>
      <div className="feedback-card-white mt-10px mb-10px text-center">
        <label className='surveyoverview__section text-xl font-bold mt-2'>
          {question}
        </label>
        {
          (id === Q_FEEDBACK_RATING) && (
            <div className="smilyContainer mt-15px">
              {
                allAnswers.map((answerItem, answerIndex) =>
                  <div key={answerIndex} onClick={() => { handleOnChange(questionIndex, answerIndex); setFeedback(questionIndex, answerIndex) }}>
                    <div className='pr-15px pt-2px pointer' style={{ paddingLeft: '5px' }}                   
                     onMouseOver={()=>{Onhover(answerIndex)}}
                     onMouseLeave={()=>{Onhover(-1)}}
                    >
                      <Image alt={answerItem.altText} src={`${API}/images/${answerItem.displayImage ? answerItem.displayImage : answerItem.iconImage}`} />
                    </div>
                    {
                      (answerIndex === 0 && <div className='font-size-10px'><label className='mr-left-8px'> {startLabel} </label></div>)
                    }
                    {
                      (answerIndex === 4 && <div className='font-size-10px'><label className='mr-left-8px'> {endLabel} </label></div>)
                    }
                  </div>
                )}
            </div>
          )
        }
        {
          (id === Q_FEEDBACK_YES_NO) && (
            <ul className="yes-no-field mt-8px">
              {answer.map((answerItem, answerIndex) =>
                <label key={answerIndex} className={`pd-10px ${answerIndex === 1 ? ' mt-15px' : ""}`} htmlFor={`${Q_MCQ}${questionIndex}_${answerIndex}`}
                onMouseOver={() => { Onhover(answerIndex) }}
                onMouseLeave={() => { Onhover(-1) }}
                >
                  <span className="yes-no-field__span pointer">
                    <div className="height-20px" onClick={() => { handleOnChange(questionIndex, answerIndex); setFeedback(questionIndex, answerIndex) }}>
                      {
                        (answerIndex == 0 && <Image  alt={answerItem.altText} src={`${API}/images/${answerItem.displayImage ? answerItem.displayImage : answerItem.iconImage}`} />)
                      }
                      {
                        (answerIndex == 1 && <Image  alt={answerItem.altText} src={`${API}/images/${answerItem.displayImage ? answerItem.displayImage : answerItem.iconImage}`} />)
                      }
                    </div>
                  </span>
                </label>
              )}
            </ul>
          )
        }
      </div>
    </Fragment>
  )
}

export default Feedback;