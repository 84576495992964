import { put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../redux/actions/configuration';
import { config } from '../services/api/configuration';
import { DEFAULT_LOCALE, BAD_REQUEST_ERROR_CODE, INTERNAL_SERVER_ERROR_CODE } from '../constants';

export function* callConfigurationApi(param) {
  try {
    const result = yield call(config, (param && param.payload) || DEFAULT_LOCALE);

    yield put(actions.receiveConfiguration(result));
  } catch (error) {
    
    try {
      if (error.response.status === BAD_REQUEST_ERROR_CODE) {
        const result = yield call(config, DEFAULT_LOCALE);
        yield put(actions.receiveConfiguration(result));
      }
    } catch (error) {
      yield put(actions.onError(error));
    }

    try {
      if (error.response.status === INTERNAL_SERVER_ERROR_CODE) {
        yield put(actions.onServerError(error));
      }
    } catch (error) {
      yield put(actions.onError(error));
    }

    yield put(actions.onError(error));
  }
}

export function* getConfiguration() {
  yield takeLatest(actions.GET_CONFIGURATION, callConfigurationApi);
}

