import './index.scss'
import {API} from '../../../constants';
import LazyLoadImage from '../../atoms/image/Image';
import { useSelector } from 'react-redux';

const SectionItem =({section, index}) => {
  const numberOfQuestions = section.questions.filter(function(question){
    const grouped = typeof question.properties[0].grouped !== 'undefined' && question.properties[0].grouped === 'true';

  return !grouped || (grouped && question.properties[0].groupQuestion.length > 0);
  }).length;
  const configuration = useSelector((state) => state.configuration);
  const { content } = configuration || {};
  const pageContentTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'survey').translations;
    

  return(
    <div className="surveyoverview__item mb-4 sm:flex sm:w-full sm:px-0 md:px-12 lg:px-16 px-12" key={index}> 
      <div className="surveyoverview__circle overflow-hidden flex sm:justify-end sm:pr-4 justify-center">
        <div role="figure" aria-labelledby={section.hero[0].image.altText} className="surveyoverview__image mt-3 float-right">
          <picture>
              <source media="(min-width:768px)" srcSet={`${API}/images/${section.hero[0].image.Desktop}`}/>
              <LazyLoadImage src={`${API}/images/${section.hero[0].image.Mobile}`} alt={section.hero[0].image.altText}/>
            </picture>
        </div>
      </div>
      <div className="sm:text-left text-center surveyoverview__sectionDescription">
        <div className="surveyoverview__section text-xl font-bold mt-2">{pageContentTranslations?.find(x=> x.name === 'section').text + ' ' + index}:</div>
        <div className="surveyoverview__sectionText">{ section.name }</div>
        <div className="surveyoverview__sectionText__NumberOfQuestions">{ numberOfQuestions + ' ' + pageContentTranslations?.find(x=> x.name === 'questions').text }</div>
      </div>
    </div>
  )
}

const SurveyOverviewSection = ({data:componentData, surveySections}) => {

  const { object, id } = componentData || {};
  const { heading } = object || {};

  return(
    <>
      <div className="surveyoverview container mb-8" id={ id }>
        <div className="surveyoverview__heading text-center font-bold md:text-3xl my-8"><h2>{ heading }</h2></div>
          <div className={`surveyoverview__content flex sm:items-center items-start justify-center`}>
            { (surveySections || []).map((section, index) => <SectionItem section={section} index={index+1} key={index} />)
            }
          </div>
      </div>
    </>
  );

}

export default SurveyOverviewSection;