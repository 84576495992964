import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfoListBulletsComponent from './InfoListBullets';
import { INFO_LIST_TICK_BULLETS_COMPONENT } from '../../../constants';

const InfoListBullets = ({ data }) => {
  return( 
    <InfoListBulletsComponent data={data} />
  )

}

export default InfoListBullets;