import React from 'react';
import {API} from '../../../constants';
import Heading from "../../atoms/heading/Heading";
import Text from "../../atoms/text/Text";
import LazyLoadImage from '../../atoms/image/Image';
import {GetErrorImageSrc} from '../../../utility';
import './PageIssue.scss';

const PageIssue = ({data, isStatic}) => {  
  const { id, object } = data || {};
  const { image, content } =object[0] || {};

  const { heading , description, cta } = content?.object || {};

  const { Desktop, Mobile } = image || {};

  return (data &&
    <div id = { id } className='page-issue flex items-center flex-col lg:py-90'>
      <div className='container mx-auto flex sm:px-12 md:px-10 lg:px-6 pb-10'>
          <div role="figure" aria-labelledby="Error Image"  className="mx-auto">
                <picture>
                  {
                    isStatic ? <img src={GetErrorImageSrc()}/> :
                    <>
                      <source media="(min-width:768px)" srcSet={`${API}/images/${Desktop}`}/>
                      <source media="(min-width:767px)" srcSet={`${API}/images/${Mobile}`}/>
                      <LazyLoadImage src={`${API}/images/${Desktop}`} alt="Error Image"/>
                    </>
                  }
                </picture>
          </div>
      </div>
      <Heading className={'md:text-2xl text-center'}>{heading}</Heading>
      <Text className={'sm:px-12 md:px-220 lg:px-330 4xl:px-500 text-center text-14'}>{description}</Text>

      <div className='cta-div text-center'>
        <a className='place-content-center mt-8 px-6 text-center hover:bg-opacity-75 transition-colors duration-200 rounded-full font-semibold py-4 xs:min-w-17 2xs:min-w-20 3xs:min-w-22 4xl:min-w-26 inline-flex cta' href={ cta.link }>
            {cta.text}
            {(cta.iconImage && cta.iconImage !== '') && 
                <LazyLoadImage className='w-5 ml-2' src={cta.iconImage} alt={cta.text} />
            }
        </a>
      </div>
      
  </div>  
  )
}

export default PageIssue;