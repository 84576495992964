import { Fragment, useState } from "react";
import { IoCheckmark, IoCloseOutline, IoAlertCircleSharp } from "react-icons/io5";
import { ANSWER, Q_MCQ } from '../../../../constants'

const YesNo = ({question, questionIndex, onValueChange, register, componentId, questionCountInPage, questionNumberLabels, questionIndexer }) => {
  const [showTick, setShowTick]= useState(false);
  const [showCross, setShowCross] = useState(false);

    const handleOnChange = (e, index, answerIndex) =>{ 
      
      if (answerIndex === 1) {
        setShowCross(true);
        setShowTick(false);
      }
      if (answerIndex === 0) {
        setShowCross(false);
        setShowTick(true);
      }

      onValueChange(e, index, answerIndex)
    };

    const { mainIndex } = questionIndexer || {};
    const { properties } = question || {};

    const { errorMessage } = properties[0] || {};
  
    return (
      <Fragment>
        {question.isValid === false && <div className="error-invalid"><span><IoAlertCircleSharp/></span>{ errorMessage }</div>}
        <div className={"card " + (question.isValid === false ? 'in-valid' : '')} id={ componentId }>
        <span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>
          <label className="control-label" htmlFor={`${ANSWER}${questionIndex}`} ><h2 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">{question.question}</h2><br />
            <ul role="list" className="yes-no-field">
              {question.answer.map((answerItem, answerIndex) => 
                <li role="listitem" className="yes-no-field__li" key={answerIndex}>
                  <input 
                    id={`${Q_MCQ}${questionIndex}_${answerIndex}`} 
                    type="radio" 
                    name={`${ANSWER}${questionIndex}`} 
                    value={answerItem.text}
                    onChange={(e) =>handleOnChange(e, questionIndex, answerIndex)}
                    defaultChecked={ answerItem.answered == 'true'}
                    ref={register({ œ: true })} 
                    className="yes-no-field__radio" 
                    autoComplete="off"
                  />
                  <label className="yes-no-field__label" htmlFor={`${Q_MCQ}${questionIndex}_${answerIndex}`}><span className="yes-no-field__span">
                    <div className="height-20px">
                      {
                        (answerIndex == 0 && <IoCheckmark />)
                      }
                      {
                        (answerIndex == 1 && <IoCloseOutline />)
                      }
                    </div>
                  </span>{answerItem.text}</label>
                </li>
              )}
            </ul>
            
          </label>
        </div>
      </Fragment>
    )
  }

  export default YesNo;