import React, { useState, Fragment } from 'react';

const AdditionalComments = ({ data, onValueChange }) => {

  const handleText = (e, value) => {
    onValueChange(value)
  }

  return (
    <Fragment>
      <div className={"card"}>
        <label className="control-label"> 
          <ul className="additional-comments-field">
          </ul>
          <div className="yesNoField-Text">
            {<div className="yesNoField-wrapper">
              <h4 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">
                {data.question}
              </h4>
              <div className="field-wrapper mt-5 px-10 sm:px-0 w-full">
                <textarea 
                  role="textbox" contenteditable="true"  
                  placeholder={data.object?.properties[0].watermark}
                  rows={8}
                  cols={35}
                  defaultValue={data.object?.answer.text}
                  maxLength={data.object?.properties[0].maxLength}
                  onChange={(e) => handleText(e, e.target.value)}
                  className="w-full px-5 py-5 field"
                  autoComplete="off"
                />
               
              </div>
            </div>
            }
          </div>
        </label>
        </div>
    </Fragment>
  )
}

export default AdditionalComments;
