import React from 'react';
import { API } from '../../../constants';
import './index.scss';

const Infofooter = ({ data }) => {
    return (
        <div className='infopodwrapper'>
            <div className='infopod-image-container flex mb-10px mt-2 mb-6'>
                
                <div className=' mt-25px'>
                    <div dangerouslySetInnerHTML={{__html:data.object.text}}></div>
                </div>
            </div>
        </div>
    )
}

export default Infofooter;
