import React, { useEffect }  from 'react';
import { useSelector } from 'react-redux';
import SurveyOverviewComponent from './SurveyOverviewSection';
import { SURVEY_OVERVIEW_SECTION_COMPONENT } from '../../../constants';

const SurveyOverviewSection = ( { data, surveySections }) => {
    

    return(data && 
      <SurveyOverviewComponent data={data} surveySections={surveySections}/>
    )
}

export default SurveyOverviewSection;