import React from 'react';
import {ENTER_KEY_CODE, SPACE_KEY_CODE} from '../.././../constants';

const Checkbox = ({ checkboxChecked, handleCheckboxClick, errorMessage }) => {
  
  const onCheckboxChange = (e) => {
    if (handleCheckboxClick) handleCheckboxClick(e)
  }

  const handleKeyChange = (e) => {
    if (e.keyCode === ENTER_KEY_CODE || e.keyCode == SPACE_KEY_CODE) {
      var checkbox = document.getElementById("checkbox");
      checkboxChecked ? checkbox.checked = false : checkbox.checked = true;
      if (handleCheckboxClick) handleCheckboxClick(e)
    }
  }

  return (
    <div className={'checkbox-container self-start'}>
      <input id="checkbox" alt={errorMessage} type="checkbox" className="form-checkbox" value={checkboxChecked} onChange={onCheckboxChange} autoComplete="off" />
      <span onKeyDown={handleKeyChange} role="chekbox" tabIndex="2"
        className="checkmark sm:h-8 sm:w-12 md:h-6 md:w-6 lg:h-6 lg:w-6 mr-3">
      </span>
    </div>
  )
}

export default Checkbox;