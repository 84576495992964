import React from 'react';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { PATH_PAGE_LOCALE, PATH_PAGE_SURVEY_SECTION, PATH_PAGE_SECTION } from '../../../constants';

function Breadcrumb(props) {
  let counter = 0;
  const location = useLocation();
  const visitedPages = useSelector((state) => state.visitedPages);
  const completedPages = useSelector((state) => state.completedPages);
  const history = useHistory();

  const queryParam = qs.parse(location.search);

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  const uniqueVisitedPages = visitedPages.filter(unique);
  const uniqueCompletedPages = completedPages.filter(unique);
  const urlSectionNo = parseInt(location.pathname?.split(PATH_PAGE_SECTION)[1]?.substring(0, 1)) - 1 || [];

  if (uniqueVisitedPages.length >= 0) {

    const uniqueMaxVisited = uniqueVisitedPages.length + 1;

    if (urlSectionNo > uniqueMaxVisited) {
      history.push(`${PATH_PAGE_SURVEY_SECTION}${uniqueMaxVisited}${PATH_PAGE_LOCALE}${queryParam.locale}`);
    }
    if (urlSectionNo == uniqueMaxVisited) {
      history.push(`${PATH_PAGE_SURVEY_SECTION}${uniqueMaxVisited}${PATH_PAGE_LOCALE}${queryParam.locale}`);
    }
  }

  return (
    <div role="navigation" aria-label="Main" className="breadcrumbContainer">
      <ol role="list" className="breadcrumb">
        {
          props.crumbs.map((crumb, ci) => {
            counter++;
            return (
              <li role="listitem" key={ci} className="breadcrumbItem" >
                <div>
                  {counter < props.crumbs.length &&
                    <>{
                      uniqueCompletedPages.includes(ci) ?
                        <div className="line-blue"></div> : <div className="line-grey"></div>
                    }</>
                  }
                  <button className="width-1px" onClick={() => { uniqueVisitedPages.includes(ci) && history.push(`${PATH_PAGE_SURVEY_SECTION}${ci + 1}${PATH_PAGE_LOCALE}${queryParam.locale}`) }}>
                    <div className={`circle ${uniqueCompletedPages.includes(ci) ? ' completed ' : ' '} ${uniqueVisitedPages.includes(ci) ? ' border-blue ' : ''} ${props.className} ${props.currentSectionNumber === ci ? 'fill' : ''}`}>
                      <span className={`circleText ${ci === 0 && ' marginleft-3 '} ${ci === 3 && ' padding-left-17px '} ${ci > props.currentSectionNumber && !uniqueVisitedPages.includes(ci) ? ' circleText-gray ' : ''}`}>{crumb}</span>
                      {uniqueCompletedPages.includes(ci) &&
                        <div className={`circleSmall ${props.currentSectionNumber === ci && ' borderSmall'}`}>
                          <span className="tick">&#10003;</span>
                        </div>
                      }
                    </div>
                  </button>
                </div>
              </li>
            );
          })
        }
      </ol>
    </div>
  );
}

export default Breadcrumb;