
import { RECEIVE_CONFIGURATION, ON_ERROR, UPDATE_CURRENT_SECTION_NUMBER, SURVEY_PAGES, VISITED_PAGES, COMPLETED_PAGES, UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE, SERVER_ERROR } from '../actions/configuration';

const initialState = {
  configuration: {},
  error: null,
  currentSectionNumber: 1,
  acceptedTCs: false,
  surveyPages: {
    pages: []
  },
  visitedPages: [],
  completedPages: [],
  serverError: false,
};

function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload && action.payload.data
      };
    case UPDATE_CURRENT_SECTION_NUMBER:
      return {
        ...state,
        currentSectionNumber: action.payload && action.payload
      };
    case SURVEY_PAGES:
      return {
        ...state,
        surveyPages: action.payload && action.payload
      };
    case VISITED_PAGES:
      return {
        ...state,
        visitedPages: action.payload && action.payload
      };
    case COMPLETED_PAGES:
      return {
        ...state,
        completedPages: action.payload && action.payload
      };
      
    case UPDATE_TERMS_AND_CONDITIONS_ACCEPTANCE:
      return {
      ...state,
      acceptedTCs: action.payload
        };
    case ON_ERROR:
      return {
        ...state,
        error: true
      };
    case SERVER_ERROR:
      return {
        ...state,
        serverError: true
      };
    default:
      return state;
  }
}

export default configurationReducer;