import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfolistParagraphAndMediaComponent from './InfolistParagraphAndMedia';
import { INFO_LIST_PARAGRAPHS_AND_MEDIA_COMPONENT } from '../../../constants';

const InfolistParagraphAndMedia = ( { data }) => {
   

    return(data && 
      <InfolistParagraphAndMediaComponent data={data}/>
    )


}

export default InfolistParagraphAndMedia;