import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import FeedBackComponent from '../../molecules/survey/Feedback'
import Image from '../../atoms/image/Image';
import { API } from '../../../constants';
import { Link } from 'react-router-dom';
import TagManager from '../../../gtm';
import {PATH_REDIRECT_USER, PATH_PAGE_LOCALE, PATH_PAGE_LOCALE_DEFAULT, SUBMIT_FEEDBACK, GILD_EVENT, BUTTONS, CLICKED } from '../../../constants';
import {PushDataLayerObject} from '../../../utility';
import './feedback.scss';

const Finish = ({ submitSuccess }) => {
  const { icon, heading, text, link } = submitSuccess;
  const linkText = submitSuccess['link-text'];

  const url = window.location.href;
  let splitedUrl = url.split('?');
  let locale = splitedUrl[1]?.split('=')[1];
  let localeUrl = `${PATH_PAGE_LOCALE}${locale}`;

  const handleLink = (e) => {
  }

  return (
    <div>
      <div className='successIcon pb-15px'>
        <Image src={`${API}/images/${icon}`} />
      </div>
      <div className="font-bold not-italic text-base sm:base normal-case pb-15px mt-8px sm:px-6 text-color">{heading}</div>
      <div className="not-italic text-base sm:base normal-case pb-4 sm:px-6 text-color">
        {text}
      </div>
      <a style={{display:"none"}} onClick={handleLink} className="not-italic text-base sm:base normal-case pb-4 sm:px-6 text-underline"
        href={localeUrl}>
        {linkText}
      </a>
      <p className="font-bold not-italic text-base sm:base normal-case pb-4 sm:px-6 text-color">
        {linkText}
      </p>
    </div>
  )
}

const Feedback = () => {
  const [isSubmited, setSubmited] = useState(false);
  let [feedbackObject, setFeedbackObject] = useState([]);
  const configuration = useSelector((state) => state.configuration);
  const history = useHistory();

  const acceptedTCs = useSelector((state) => state.acceptedTCs);

  if(acceptedTCs === false){
    let path = `?locale=${PATH_PAGE_LOCALE_DEFAULT}`;
    history.push(PATH_REDIRECT_USER + path);
  }

  const { surveyFeedbackPage } = configuration;
  if (!surveyFeedbackPage) return null;
  const { content } = surveyFeedbackPage || {};
  const { components } = content || {};
  const { object } = components[0] || [];
  const cObject = object[0] || {};
  const sectionSuccess = cObject['section-success'];
  const sectionEmail = cObject['section-email'];
  const sectionForm = cObject['section-form'];
  const { questions } = sectionForm;
  const submitCta = sectionForm['submit-cta'];
  const submitSuccess = sectionForm['submit-success'];

  const handleSubmit = (e) => {
    setSubmited(true);
    PushDataLayerObject(configuration,
      GILD_EVENT,
      BUTTONS,
      CLICKED,
      SUBMIT_FEEDBACK
    );

    const result = feedbackObject.filter(x => x.event != "");
    result.forEach((feedback) => {
      TagManager.dataLayer({ dataLayer: { ...feedback } });
    })
  }

  if (feedbackObject.length === 0) {
    let array = [];
    const { surveyFeedbackPage } = configuration;
    const { language } = surveyFeedbackPage;
    const { content } = surveyFeedbackPage;
    const { pageInfo } = content;
    const { intendedAudience, subject } = pageInfo;
    questions.forEach(quest => {
      const feedBack = {
        event: "",
        eventCategory: "Feedback",
        eventAction: "Answered",
        eventLabel: "",
        intendedAudience: intendedAudience,
        subeject: subject,
        language: language
      }
      array.push(feedBack);
    });
    setFeedbackObject(array);
  }

  const handleOnChange = (questionIndex, anserIndex) => {
   
    questions[questionIndex].answer.forEach((ans, index) => {
      if (anserIndex === index) {
        questions[questionIndex].answer[index].answered = "true";
      } else {
        questions[questionIndex].answer[index].answered = "false";
      }
    });
  }

  const setFeedbackAnswers = (index, event, eventLabel) => {
    feedbackObject[index].event = event;
    feedbackObject[index].eventLabel = eventLabel;
    setFeedbackObject(feedbackObject);
  }

  return (
    <Fragment>
      <div className="default-color">
        <div className="feedback-card mt-10px flex">
          <div className="feedback-card-wrapper sm:items-center items-start justify-center text-center">
            <div className='successIcon pb-15px'>
              <Image src={`${API}/images/${sectionSuccess.icon}`} wrapperClassName={''} />
            </div>
            <div className="surveyoverview__heading text-center font-bold md:text-3xl mt-8px pb-15px"><h2>{sectionSuccess.text}</h2></div>
            <div className='line  pb-15px'>
              <div className="vl"></div>
              <span className='arrow-down'>&#x25BC;</span>
            </div>
            <div className='successIcon mt-8px pb-15px'>
              <Image src={`${API}/images/${sectionEmail.icon}`} />
            </div>
            <div className="surveyoverview__heading text-center font-bold md:text-3xl mt-8px"><h2>{sectionEmail.heading}</h2></div>
            <div className='mt-12px'>
              <label className='feedback-text'>
                {sectionEmail.text1}
              </label><br /><br />
              <div className="font-bold text-base sm:base normal-case pb-4 sm:px-6 font-color">
                {sectionEmail.text2}
              </div>
            </div>
          </div>
        </div>
        <div role="form" id="realimentacion" aria-label="realimentacion" className="feedback-container">
            {!isSubmited && questions.map((x, index) => {
              return (
                <FeedBackComponent key={index} currentQuestion={x} questionIndex={index} onValueChange={handleOnChange} setFeedbackAnswers={setFeedbackAnswers} />
              )
            })
            }
          </div>
        <div className="next-btn-wrapper flex w-full text-center py-10 justify-center md:fixed md:bottom-0 md:left-0 md:right-0 md:flex md:justify-center mb-4 md:mb-0 md:z-50 md:p-4">
          {
            (isSubmited ? <Finish submitSuccess={submitSuccess} /> : (<button onClick={handleSubmit} className="w-1/5 sm:w-1/2 md:w-1/2 lg:w-1/3 py-3 rounded-full btn-primary font-semibold text-poppins text-base block btn-background">
              {submitCta.text}
            </button>))
          }
        </div>
      </div>
    </Fragment>
  )
}

export default Feedback;