import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './index.scss'
import FooterComponent from './index';
import { Fragment } from 'react';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { API, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING, TERM_AND_CONDITION, PRIVACY_POLICY, COOKIE_POLICY } from '../../../constants';
import { PushDataLayerObject} from '../../../utility';
import LazyLoadImage from '../../atoms/image/Image';

import logolapaz from './../../../assets/images/logosfooter/lapaz.svg';
import logogesida from './../../../assets/images/logosfooter/gesida.svg';
import logoapoyocon from './../../../assets/images/logosfooter/apoyocon.svg';
import logoris from './../../../assets/images/logosfooter/ris.svg';

const Footer = ({data}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const configuration = useSelector((state) => state.configuration);
    const { content } = configuration || {};
    const pageContentTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'page-content').translations;
    const terms = pageContentTranslations?.find(x=> x.name === 'content-terms');
    const privacy = pageContentTranslations?.find(x=> x.name === 'content-privacy');
    const cookies = pageContentTranslations?.find(x=> x.name === 'content-cookies');
    const footerTranslations = content?.content?.labels?.sections?.find(x=> x.name === 'footer');
    const footerTexts = footerTranslations?.footerTexts;
    const footerCopyright = footerTranslations?.footerCopyright;
    const footerImage = footerTranslations?.image;

    const pageContent = {
      'terms': terms,
      'privacy': privacy,
      'cookies': cookies
    };

    const onOpenModal = (origin) => {
        pageContent[origin] && function() {
            setModalContent(pageContent[origin]?.text);
            setModalTitle(pageContent[origin]?.heading);
            setModalOpen(true);
        }();

        switch(origin) {
          case 'terms': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + TERM_AND_CONDITION);
            return;
          }
          case 'privacy': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + PRIVACY_POLICY);
            return;
          }
          case 'cookies': {
            PushDataLayerObject(configuration, GILD_EVENT, INTERNAL_LINKS, CLICKED, SEEING + " " + COOKIE_POLICY);
            return;
          }
          default: {
            return;
          }
        }
      };

    const onCloseModal = () => {
      setModalOpen(false);
    };

    const modalClassNames = {
        modal: 'rounded-2xl pt-10 px-8',
        closeIcon: 'optincta-modal__close-icon'
      }

  return(   <>
    <Modal open={modalOpen} onClose={onCloseModal} classNames={modalClassNames} blockScroll={false}>
        <div>
            <h2 tabIndex="0" className={'optincta-modal__title font-semibold mb-6 md:mt-8 spotlight'}>{modalTitle}</h2>
            <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </div>
    </Modal>

    <Fragment>
        { (data || []). length > 0 ? <footer className=" md:hidden mx-auto">
        <div>
        <div className="w-full text-center mb-2 mt-6 logo">
            <p className="px-4 sm:px-3 border-0 inline leading-7 text-sm">
                <span className="text-sm font-poppins">Esta herramienta ha sido desarrollada por</span>
            </p>
          </div>
          <div className="w-full text-center mb-2 mt-6 logo">
            <div>
              {(footerTexts || []).map((footerText, footerTextIndex) => {
                return(
                  <p className={`px-4 sm:px-3 border-0 inline leading-7 text-sm`} key={footerTextIndex}><span className="text-sm font-poppins">{footerText}</span></p>
                  )
                })
              }
              <picture className={`max-auto logoImage`}>
                <source media="(min-width:768px)" srcSet={`${API}/images/${footerImage.Desktop}`}/>
                <source media="(min-width:767px)" srcSet={`${API}/images/${footerImage.Mobile}`}/>
                <LazyLoadImage className="text-sm font-poppins mx-auto" width={"110"} src={`${API}/images/${footerImage.Desktop}`} alt={footerImage.altText}/>
              </picture>
            </div>
          </div>
          <div className="py-4 px-32 flex flex-col md:flex-row md:items-center md:justify-center">
                    <div className="w-full text-center">
                        <div className="flex justify-between">
                            <div className="w-2/8 px-8">
                                
                                
                            </div>
                            <div className="w-1/8 px-8">
                                <p className="text-sm font-poppins text-left">Con el apoyo de</p>
                                <img  className="text-sm font-poppins mx-auto" src={logolapaz}  alt="La Paz" />
                            </div>
                            <div className="w-1/8 px-8">
                                <p className="text-sm font-poppins"> &nbsp;</p>
                                <img  className="text-sm font-poppins mx-auto" src={logogesida} alt="GESIDA" />
                            </div>
                            <div className="w-1/8 px-8">
                                <p className="text-sm font-poppins">&nbsp; </p>
                                <img  className="text-sm font-poppins mx-auto" src={logoapoyocon} alt="Apoyo con" />
                            </div>
                            <div className="w-1/8 px-8">
                                <p className="text-sm font-poppins text-left">Y con el aval de</p>
                                <img  className="text-sm font-poppins mx-auto" src={logoris} alt="RIS" />
                            </div>
                            <div className="w-2/8 px-8">
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
          <div className="py-3 flex md:flex md:items-center md:justify-center " >
            <ul className="w-full text-center">
              {
                  (data || []).map((e, i) => {
                      const { name, text } = e || {};
                      const classes = ((data || []).length === i+1) ? "border-0" : "border-r";
                      return (
                      <li className={`px-4 sm:px-3 ${classes} capitalize inline leading-7 text-sm`} key={ i }>
                            <a className="text-sm font-poppins" href="#" onClick={(e)=>{ e.preventDefault();onOpenModal(name);}}>{ text }</a>
                      </li>
                      )
                  })
              }
            </ul>
          </div>
          
          <div className="w-full text-center mb-2 mt-6 logo ">
            <p className="px-4 sm:px-3 border-0 inline leading-7 text-sm">
                <span className="text-sm font-poppins">{footerCopyright}</span>
            </p>
          </div>
        </div>
    </footer> : "" }
    </Fragment>
    </>
)
}

export default Footer;
