import React, { Fragment, useEffect, useState, useRef } from 'react';
import { IoCheckmark, IoCloseOutline, IoAlertCircleSharp, IoAdd, IoClose } from "react-icons/io5";
import { Q_YES_NO_WITH_MULTIPLE_TEXT, Q_MCQ, ANSWER } from '../../../../constants';

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

const YesNoWithMultiText = ({ question, questionIndex, onValueChange, addMultitextItem, register, componentId, removeAnswerfromIndex, questionCountInPage, removeAllAnswers, questionNumberLabels, questionIndexer }) => {
  const [showSpecifyArea, setShowSpecifyArea] = useState(false);
  const [subQuestionField, setInputFocus] = useFocus();

  const [thisQuestionIndex, setThisQuestionIndex] = useState(0);
  const [thisAnswerIndex, setThisAnswerIndex] = useState(0);
  const [text, setText] = useState('');

  const [showTick, setShowTick] = useState(false);
  const [showCross, setShowCross] = useState(false);

  const { answer, subquestion, properties: questionProperties } = question;
  const { errorMessage: questionMessage } = questionProperties[0] || {};
  const { properties } = subquestion[0] || {};
  const subquestionQuestion = subquestion[0].question || {};
  const { watermark } = properties[0] || {};
  const { mainIndex } = questionIndexer || {};
  let show;
  let textData;

  textData = subquestion[0]?.answer[1]?.text

  if (textData) {
    show = true;
  }

  const handleAddItem = () => {
    if (text) {
      setText('');
      addMultitextItem(thisQuestionIndex, text, thisAnswerIndex);
      onValueChange(null, thisQuestionIndex, 0, null, Q_YES_NO_WITH_MULTIPLE_TEXT, true);
    }
  };

  const handleOnChange = (e, index, answerIndex, text) => {
    setThisQuestionIndex(index);
    setThisAnswerIndex(answerIndex);

    if (answerIndex == 0) {
      setShowCross(false);
      setShowTick(true);
      setShowSpecifyArea(true);

      onValueChange(e, index, answerIndex, null, Q_YES_NO_WITH_MULTIPLE_TEXT);
    }
    if (answerIndex > 0) {
      setShowCross(true);
      setShowTick(false);
      setShowSpecifyArea(false)
      onValueChange(e, index, answerIndex, null, Q_YES_NO_WITH_MULTIPLE_TEXT);
      removeAllAnswers(index)
    }
  };

  const handleText = (questionIndex, value, answerIndex) => {
    const x = value;
    setThisQuestionIndex(questionIndex);
    setThisAnswerIndex(answerIndex);
    setText(x);
  }

  return (

    <Fragment>
      {question.isValid === false && <div className="error-invalid"><span><IoAlertCircleSharp /></span>{questionMessage}</div>}
      <div className={"card " + (question.isValid === false ? 'in-valid' : '')} id={componentId}>
      <span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>
        <label className="control-label" htmlFor={`${ANSWER}${questionIndex}`} ><h2 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">{question.question}</h2><br />

          <ul role="list" className="yes-no-field">
            {question.answer.map((answerItem, answerIndex) =>
              <li role="listitem" className="yes-no-field__li" key={answerIndex}>
                <input
                  id={`${Q_MCQ}${questionIndex}_${answerIndex}`}
                  type="radio"
                  name={`${ANSWER}${questionIndex}`}
                  value={answerItem.text}
                  onChange={(e) => handleOnChange(e, questionIndex, answerIndex, answerItem.text)}
                  defaultChecked={answerItem.answered == 'true'}
                  ref={register({ œ: true })}
                  className="yes-no-field__radio"
                  autoComplete="off"
                />
                <label className="yes-no-field__label" htmlFor={`${Q_MCQ}${questionIndex}_${answerIndex}`}><span className="yes-no-field__span">
                  <div className="height-20px">
                    {
                      (answerIndex == 0 && <IoCheckmark />)
                    }
                    {
                      (answerIndex == 1 && <IoCloseOutline />)
                    }
                  </div>
                </span>{answerItem.text}</label>
              </li>
            )}
          </ul>
          {(showSpecifyArea || show) && <div className="yes-no-wrapper">
            <h4 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">
              {subquestionQuestion}
            </h4>
            <div className="field-wrapper mt-5 px-10 sm:px-0 w-full">
              <div className="item-qus w-full mt-5 sm:px-0 p-0px">
                <input
                  type='text'
                  id="subQuestionField"
                  ref={subQuestionField}
                  value={text}
                  placeholder={watermark}
                  onChange={(e) => handleText(questionIndex, e.target.value)}
                  className="w-full px-5 py-5 field"
                  autoComplete="off"
                />
                <button onClick={handleAddItem} className="btn-pad-25px">
                  <IoAdd />
                </button>
              </div>

              <Fragment>
                {
                  subquestion[0].answer.map((x, index) =>               
                        x.text &&(
                        <div key={index} className="item-wrapper w-full mt-5 px-5 sm:px-0"> <label className="w-full mutlitext-label">
                          {x.text}
                        </label><button onClick={()=>removeAnswerfromIndex(questionIndex,index)}> &#8722;</button><br />
                        </div>)
                   )
                }
              </Fragment>
            </div>
          </div>}
        </label>
      </div>
    </Fragment>
  )
}

export default YesNoWithMultiText;