import React, { useEffect, useState, Fragment } from 'react';
import { IoCheckmark, IoCloseOutline, IoAlertCircleSharp } from "react-icons/io5";
import { Q_MCQ, ANSWER } from '../../../../constants';

const YesNoWithText = ({ question, questionIndex, onValueChange, register, questionCountInPage, componentId, revertSubQuestionsAnswer, subQuestionExists, questionNumberLabels, PushDataLayerYesNoTypes, questionIndexer }) => {
  const [showTick, setShowTick]= useState(false);
  const [showCross, setShowCross] = useState(false);
  const [showSpecifyArea, setShowSpecifyArea] = useState(false);
  const [textAnswer, setTextAnswer] = useState('');
  const [thisQuestionIndex, setThisQuestionIndex] = useState(0);
  const [thisAnswerIndex, setThisAnswerIndex] = useState(0);
  
  const { answer, subquestion } = question;
  const { properties: questionProperties } = question || {};
  const { errorMessage: questionMessage } = questionProperties[0] || {};

  const { mainIndex } = questionIndexer || {};
  const { properties } = subquestion;
  
  const { errorMessage } = properties[0] || {};
  let show;
  let textData;
  const isYesSelected = question.answer[0].answered === 'true' ? true : false;
  
  if (isYesSelected) {
    show = true;
    textData = subquestion.answer[0].text
  } else {
    show = false;
    textData = '';
  }
  const handleOnChange = (e, index, answerIndex, text) => {
    setThisQuestionIndex(index);
    setThisAnswerIndex(answerIndex);
    if (answerIndex == 0) {
      setShowCross(false);
      setShowTick(true);
      setShowSpecifyArea(true);
      subQuestionExists(index);
      PushDataLayerYesNoTypes(index, 0);
    }
    if (answerIndex > 0) {
      setShowCross(true);
      setShowTick(false);
      setShowSpecifyArea(false);
      question.showTextArea = false;
      onValueChange(e, index, answerIndex);
      revertSubQuestionsAnswer(index)
    }
  };

  const handleText = (e, value) => {
    const text = value;
    if (thisAnswerIndex == 0) {
      setTextAnswer(text);
      const subquestionScoreMessage = value;
      onValueChange(e, questionIndex, 0, subquestionScoreMessage, question.id, false)
    }
    if (!value) {
      onValueChange(e, questionIndex, 0, "", question.id, false)
    }
  }

  return (
    <Fragment>
    {(question.isValid === false && showSpecifyArea === false )&& <div className="error-invalid"><span><IoAlertCircleSharp/></span>{ questionMessage }</div>}
      {(showSpecifyArea === true && textAnswer === "") && <div className="error-invalid"><span><IoAlertCircleSharp/></span>{errorMessage}</div> }      <div className={"card " + ((question.isValid === false || (showSpecifyArea === true && textAnswer === "")) ? 'in-valid' : '')} id={ componentId }>
      <span className="card__numbers text-center sm:text-left text-xs font-semibold">{ questionNumberLabels[2].text } {mainIndex} { questionNumberLabels[3].text } {questionCountInPage}</span>
        <label className="control-label" htmlFor={`${ANSWER}${questionIndex}`} ><h2 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">{question.question}</h2><br />
          <ul role="list" className="yes-no-field">
            {question.answer.map((answerItem, answerIndex) =>
              <li role="listitem" className="yes-no-field__li" key={answerIndex}>
                <input
                  id={`${Q_MCQ}${questionIndex}_${answerIndex}`}
                  type="radio"
                  name={`${ANSWER}${questionIndex}`}
                  value={answerItem.text}
                  onChange={(e) => handleOnChange(e, questionIndex, answerIndex, answerItem.text)}
                  defaultChecked={answerItem.answered == 'true'}
                  ref={register({ œ: true })}
                  className="yes-no-field__radio"
                  autoComplete="off"
                />
                <label className="yes-no-field__label" htmlFor={`${Q_MCQ}${questionIndex}_${answerIndex}`}><span className="yes-no-field__span">
                <div className="height-20px">
                    {
                      (answerIndex == 0 && <IoCheckmark />)
                    }
                    {
                      (answerIndex == 1 && <IoCloseOutline />)
                    }
                  </div>
                </span>{answerItem.text}</label>
              </li>
            )}
          </ul>
          <div className="yesNoField-Text mt-8">
            {(showSpecifyArea || show || question.showTextArea) && <div className="yesNoField-wrapper">
              <h4 className="question-title text-center sm:text-left text-poppins text-app-900 text-xl font-semibold">
                {subquestion.question}
              </h4>
              <div className="field-wrapper mt-5 px-10 sm:px-0 w-full">
                <textarea 
                  role="textbox" contenteditable="true" 
                  placeholder={subquestion.properties[0].watermark}
                  rows={5}
                  cols={35}
                  defaultValue={textData ? textData : textAnswer}
                  onChange={(e) => handleText(e, e.target.value)}
                  className="w-full px-5 py-5 field"
                  autoComplete="off"
                />
               
              </div>
            </div>
            }
          </div>
        </label>
        </div>
    </Fragment>
  )
}

export default YesNoWithText;