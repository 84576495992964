import React from 'react';
import queryString from 'query-string';
import './index.scss';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from './../../../assets/images/logo.svg';
import { DEFAULT_LOCALE } from '../../../constants/index';
import LazyLoadImage from '../../atoms/image/Image';

export default props => {
    const configuration = useSelector((state) => state.configuration);

    const { content } = configuration || {};
    const logoTranslation = content?.content?.labels?.sections[0]?.translations[0];

    const { search } = useLocation();
    let params = queryString.parse(search);

    const { locale } = params || {};

    const defaultUrl = locale ? locale : DEFAULT_LOCALE;

    return (
        <header className={`header pt-12 sm:pt-6 md:pt-12 lg:pt-12 2xl:pt-12 3xl:pt-12 px-20 sm:px-10 md:px-20 xl:px-20 lg:px-20 2xl:px-20 3xl:px-20 4xl:px-20 ${props.isMainLayout && 'main-layout'}`}>
            <div className="header__container container">
                <Link role='banner' alt={logoTranslation?.text}  to={`/?locale=${defaultUrl}`} tabIndex='1'>
                    <LazyLoadImage src={logo} className="logo" alt={logoTranslation?.text} />
                </Link>
            </div>
        </header>
    );    
}
